/* Expansion panel */
mat-expansion-panel.mat-expansion-panel {
    --mat-expansion-header-text-color: var(--color-primary-light);
    --mat-expansion-container-text-color: var(--color-primary-light);
    --mat-expansion-container-shape: 5px;
    box-shadow: none !important;

    .mat-expansion-indicator {
        padding-bottom: 4px;
    }

    .mat-expansion-panel-body {
        background-color: var(--background-color);
    }
}

mat-expansion-panel.mat-expansion-panel.dark {
    background-color: var(--color-primary);
    --mat-expansion-header-collapsed-state-height: 48px;
    --mat-expansion-header-expanded-state-height: 48px;
    --mat-expansion-header-text-color: var(--background-color);
    --mat-expansion-header-indicator-color: var(--background-color);
    --mat-expansion-container-text-color: var(--color-primary-light);

    > .mat-expansion-panel-content {
        border-left: 1px solid var(--color-grey-1);
        border-right: 1px solid var(--color-grey-1);
        border-bottom: 1px solid var(--color-grey-1);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    > .mat-expansion-panel-body {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    &:not(.mat-expanded),
    &:not(.mat-expansion-panel-spacing) {
        border-radius: 5px;
    }
}

mat-expansion-panel.mat-expansion-panel.blue {
    background-color: var(--color-blue-2);

    > mat-expansion-panel-header.mat-expansion-panel-header:hover {
        background-color: var(--color-blue-2) !important;
    }
}

mat-expansion-panel.mat-expansion-panel.esm-nested-panel {
    --mat-expansion-header-collapsed-state-height: 48px;
    --mat-expansion-header-expanded-state-height: 48px;
    --mat-expansion-header-indicator-color: var(--color-primary-light);
    --mat-expansion-header-disabled-state-text-color: var(
        --color-primary-light
    );
    border-radius: 0 !important;

    .mat-expansion-indicator {
        margin-left: 16px;
    }

    .mat-expansion-panel-header-title {
        margin-right: 0;
    }

    .mat-expansion-panel-header-description {
        margin-right: 0;
    }

    .mat-expansion-panel:last-of-type {
        margin-bottom: 0;
    }

    .mat-expansion-panel-header {
        min-height: 48px;
        height: auto;
        border-top: 1px solid var(--color-grey-1);
        border-radius: 0;
        padding: 0 16px;
    }

    .mat-expansion-panel-header.mat-expanded {
        border-bottom: 1px solid var(--color-grey-1);
    }

    .mat-content.mat-content-hide-toggle {
        margin-right: 25px;
    }
}

mat-expansion-panel.mat-expansion-panel.esm-nested-panel.caret {
    > .mat-expansion-panel-header {
        min-height: 56px;
    }

    > .mat-expansion-panel-header.mat-expanded {
        background-color: var(--color-grey-4);
        border-bottom: 2px solid var(--color-primary);
    }
}

.mat-expansion-panel.blue-carret {
    --mat-expansion-header-indicator-color: var(--color-secondary) !important;
}

.mat-expansion-panel.score-overview {
    .mat-expansion-panel-header.mat-expanded {
        background-color: rgba(0, 0, 0, 0.04); // to match hover state
    }

    .mat-expansion-panel-body {
        padding: 0;
        max-height: 760px;
        overflow: auto;

        @media (min-width: $media-xlg-min) {
            max-height: 670px;
        }
    }
}

.mat-expansion-panel.score-overview-nested {
    .mat-expansion-panel-header.mat-expanded {
        background-color: white;
    }

    .mat-expansion-panel-header {
        border-top-width: 0 !important;
    }

    .mat-expansion-panel-body {
        padding: 0 30px;
    }
}

.mat-expansion-panel-body::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    background: var(--color-grey-4);
}

.mat-expansion-panel-body::-webkit-scrollbar-thumb {
    border-radius: 5px !important;
    background: var(--color-grey-1) !important;
}

mat-expansion-panel.mat-expansion-panel.asset-registers {
    --mat-expansion-header-disabled-state-text-color: var(
        --color-primary-light
    );

    .mat-expansion-panel-header {
        min-height: 60px;
        padding: 0 16px;
        border-top: 1px solid var(--color-grey-1);
        border-radius: 0;

        &.mat-expanded {
            background-color: var(--color-grey-5);
        }
    }

    .mat-expansion-panel-body {
        padding-left: 0;
        padding-right: 0;
    }

    .mat-content.mat-content-hide-toggle {
        margin-right: 0;
    }

    .mat-expansion-panel-header-title {
        margin-right: 0;
    }

    &:last-child {
        .mat-expansion-panel-header:not(.mat-expanded) {
            border-bottom: 1px solid var(--color-grey-1);
        }
    }
}
