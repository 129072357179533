@import './variables.scss';

/* Overwrite */

/* Mat Chips */
.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    background-color: var(--color-secondary-light) !important;
    border-radius: 5px !important;

    .mdc-evolution-chip__text-label {
        color: var(--color-secondary) !important;
    }

    .mdc-evolution-chip__icon--trailing {
        color: var(--color-secondary) !important;
    }

    .mat-mdc-chip-action-label {
        font-size: 11px !important;
    }
}

/* Mat Icon */
mat-icon.mat-icon {
    min-width: 22px;
    width: 22px;
    height: 22px;
    font-size: 22px;
}

mat-icon.mat-icon.qpx-icon-sm {
    min-width: 16px;
    width: 16px;
    height: 16px;
    font-size: 16px;
}

mat-icon.mat-icon.qpx-icon-xs {
    min-width: 13px;
    width: 13px;
    height: 13px;
    font-size: 13px;
}

mat-icon.disabled {
    color: var(--color-grey-1);
}

/* Mat Stars */
qpx-health-rating.mat-stars-2x {
    mat-icon.mat-icon {
        font-size: 25px;
        min-width: 25px;
        width: 25px;
        height: 25px;
    }
}

qpx-health-rating.mat-stars-small {
    mat-icon.mat-icon {
        font-size: 16px;
        width: 16px;
        height: 16px;
    }
}

qpx-dropdown.contact-type-selector {
    padding: 0rem;
    select {
        width: 100%;
        border-color: var(--card-border-color);
    }
}

/* Mat Progress Bar*/
mat-progress-bar.mat-mdc-progress-bar {
    --mdc-linear-progress-track-color: var(--progress-bar-track-color);
    --mdc-linear-progress-track-height: 8px;
    --mdc-linear-progress-active-indicator-height: 8px;
}

/* Mat Button */
button.mdc-button.mdc-button--outlined.mat-primary {
    .mat-mdc-outlined-button:not(:disabled) {
        -mdc-outlined-button-label-text-color: var(--color-primary);
    }
}

button.mdc-button.mdc-button--outlined.mat-accent.mat-mdc-outlined-button:not(
        :disabled
    ) {
    background-color: var(--color-secondary) !important;
    border-color: var(--color-secondary) !important;

    .mdc-button__label,
    mat-icon.mat-icon {
        color: var(--background-color);
    }
}

.mat-mdc-outlined-button.mat-unthemed {
    --mdc-outlined-button-label-text-color: var(--color-primary);
}

button.mdc-button {
    height: 40px !important;
}

button.mdc-button.mat-mdc-outlined-button:not(:disabled) {
    mat-icon.mat-icon {
        color: var(--color-secondary);
    }
}

button.mat-mdc-unelevated-button:not(:disabled) {
    color: var(--background-color);
}

/* Mat Label */
mat-label {
    color: var(--color-primary-light);
}

mat-label.disabled {
    color: var(--color-grey-1);
}

/* Mat Hint */
mat-hint.disabled {
    color: var(--color-grey-1);
}

/* Mat Select */

mat-select.mat-mdc-select .mat-mdc-select-placeholder {
    color: var(--color-primary) !important;
}

.mat-mdc-select-panel {
    height: auto;
    max-height: 400px !important;
}

.mat-mdc-select-panel.esm-select-category {
    height: auto;
    max-height: 300px !important;

    .mat-mdc-select-arrow {
        --mat-select-enabled-arrow-color: var(--color-secondary) !important;
    }
}

.mat-mdc-select-panel.building-details-class {
    height: auto;
    max-height: 300px !important;
}

.mat-mdc-select-panel.building-units {
    height: auto;
    max-height: 300px !important;
}

.cdk-overlay-pane:has(.mat-mdc-select-panel.dropdown-auto-width) {
    width: auto !important;
}

/* Mat Menu */
.mat-mdc-menu-content {
    padding: 0 !important;
}

/* Mat Error */
.mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
}

/* Mat Divider */
.mat-divider {
    border-top-color: var(--card-border-color) !important;
}

/* Mat Spinner */
mat-spinner.qpx-loader {
    width: 40px !important;
    height: 40px !important;
}

mat-spinner.qpx-loader-sm {
    width: 25px !important;
    height: 25px !important;
}

.load-center-absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Mat Datepicker Toggle */
mat-datepicker-toggle.mat-datepicker-toggle {
    --mat-datepicker-toggle-icon-color: var(--color-secondary);
    --mat-datepicker-toggle-active-state-icon-color: var(--color-secondary);
}

.mat-datepicker-content .mat-calendar.small {
    height: 255px;
    overflow: auto;
}

.mat-datepicker-content .mat-calendar.ignore-days {
    button.mat-calendar-period-button.mdc-button {
        pointer-events: none;
        visibility: hidden;
    }

    .mat-calendar-controls {
        margin: 8px 0;
    }

    .mat-calendar-body-label {
        color: var(--color-primary);
        font-size: 18px;
    }
}

/* Mat Divider */
mat-divider.mat-divider {
    border: 0 !important;
    border-top: 1px solid var(--divider-color) !important;
}

/* mat-option */
.mat-mdc-option.mdc-list-item--disabled.opacity-free {
    .mdc-list-item__primary-text {
        opacity: 1;
    }
}

.mat-mdc-option.full-width,
.mat-mdc-option.mdc-list-item--disabled.full-width {
    .mdc-list-item__primary-text {
        width: 100%;
    }
}

/* mat-radio-button */
.mat-mdc-radio-button.mat-primary {
    --mat-radio-checked-ripple-color: transparent;
    --mat-radio-ripple-color: transparent;
}
