mat-slide-toggle.mat-mdc-slide-toggle.portfolio-and-community {
    --mdc-switch-unselected-focus-handle-color: #7c9098;
    --mdc-switch-unselected-pressed-handle-color: #7c9098;
    --mdc-switch-unselected-focus-state-layer-color: #7c9098;
    --mdc-switch-unselected-handle-color: #7c9098;
    --mdc-switch-unselected-pressed-state-layer-color: #7c9098;
    --mdc-switch-unselected-hover-handle-color: #7c9098;
    --mdc-switch-unselected-hover-state-layer-color: #7c9098;

    --mdc-switch-selected-handle-color: #7c9098;
    --mdc-switch-selected-pressed-handle-color: #7c9098;
    --mdc-switch-selected-pressed-state-layer-color: #7c9098;
    --mdc-switch-selected-hover-state-layer-color: #7c9098;
    --mdc-switch-selected-hover-handle-color: #7c9098;
    --mdc-switch-selected-focus-state-layer-color: #7c9098;
    --mdc-switch-selected-focus-handle-color: #7c9098;

    --mdc-switch-selected-track-color: #e0e0e0;
    --mdc-switch-selected-pressed-track-color: #e0e0e0;
    --mdc-switch-selected-hover-track-color: #e0e0e0;
    --mdc-switch-selected-focus-track-color: #e0e0e0;
}

mat-slide-toggle.mat-mdc-slide-toggle.mat-primary.disabled-dark {
    --mat-switch-disabled-selected-handle-opacity: 1;
    --mat-switch-disabled-selected-icon-opacity: 1;
    --mdc-switch-disabled-unselected-icon-opacity: 1;
    --mdc-switch-disabled-track-opacity: 1;
    --mdc-switch-disabled-label-text-color: var(--color-primary);
    --mdc-switch-disabled-selected-handle-color: #02add7;
    --mdc-switch-disabled-selected-track-color: #4ec6e3;
}
