:root {
    //////////////
    /// COLORS ///
    //////////////

    /// Figma Colors (Internal)
    --internal-navy: #103441;
    --internal-blue: #02add7;
    --internal-medium-grey: #758c95;
    --internal-light-grey: #d2dadf;
    --internal-background-grey: #fafafa;
    --internal-white: #ffffff;
    --internal-status-complete: #b6e4ca;
    --internal-status-compliant: var(--internal-blue);
    --internal-status-upcoming: #cbc5f2;
    --internal-status-overdue: #ed6e75;
    --internal-status-not-critical: var(--internal-light-grey);
    --internal-status-critical: #de8013;
    --internal-status-defect: var(--internal-status-critical);
    --internal-status-not-compliant: var(--internal-status-critical);

    /// Contextual Colors
    --color-primary: var(--internal-navy);
    --color-primary-muted: var(--internal-medium-grey);
    --color-secondary: var(--internal-blue); // ??
    --color-accent: var(--internal-blue);
    --color-input: var(--internal-navy);
    --color-input-disabled: var(--internal-medium-grey);
    --color-input-label: var(--internal-medium-grey);
    --color-input-placeholder: var(--internal-medium-grey);
    --color-border: var(--internal-light-grey);
    --color-background: var(--internal-white);
    --color-background-muted: var(--internal-background-grey);
    --color-complete: var(--internal-status-complete);
    --color-compliant: var(--internal-status-compliant);
    --color-upcoming: var(--internal-status-upcoming);
    --color-overdue: var(--internal-status-overdue);
    --color-not-critical: var(--internal-status-not-critical);
    --color-critical: var(--internal-status-critical);
    --color-defect: var(--internal-status-defect);
    --color-not-compliant: var(--internal-status-not-compliant);

    /// The below colours need to be reviewed for further use
    /// and replaced with the above contextual colors where possible
    --color-primary-light: var(--color-primary-muted);
    --color-secondary: var(--internal-blue);
    --color-secondary-light: #e5f7fb; // not a figma variable
    --color-success: #50cd88; // not a figma variable
    --color-success-light: var(--internal-status-complete);
    --color-success-dark: #47be7d; // is used; not a figma variable
    --color-danger: #ca0606; // not a figma variable
    --color-danger-2: #b51849; // not a figma variable
    --color-danger-light: var(--internal-status-overdue);
    --color-grey-1: var(--internal-light-grey);
    --color-grey-2: #dfe4e8; // not a figma variable
    --color-grey-3: #666666; // not a figma variable
    --color-grey-4: #f3f5f7; // not a figma variable
    --color-grey-5: var(--internal-background-grey);
    --color-grey-6: #f1f1f2; // not a figma variable
    --color-grey-7: #eaeaea; // not a figma variable
    --color-grey-8: #9fafb5; // not a figma variable
    --color-green-light: #e0fbef; // not a figma variable
    --color-red-light: #ffeded; // not a figma variable
    --color-blue: #8ecbdd; // not a figma variable
    --color-blue-2: #7092be; // not a figma variable
    --color-blue-3: #8fb0ff; // not a figma variable
    --color-blue-light: #ebf9fc; // not a figma variable
    --color-yellow: #eac137; // not a figma variable
    --color-orange: var(--internal-status-critical);
    --color-orange-light: #faecdc; // not a figma variable
    --color-terra-cotta: #ea6a5e; // not a figma variable
    --color-purple: #cabeff; // not a figma variable

    --background-color: var(--color-background);
    --scrollbar-color: var(--color-grey-6);

    --status-completed-bg: var(--color-success-light);
    --status-overdue-bg: var(--color-danger-light);
    --status-upcoming-bg: var(--color-yellow);
    --status-not-started-bg: var(--color-primary-light);
    --status-compliant-bg: var(--color-secondary);
    --status-not-compliant-bg: var(--color-primary);
    --status-critical-bg: var(--color-orange);
    --status-non-critical-bg: var(--color-grey-4);

    --warning: var(--color-yellow);
    --danger: var(--color-danger);
    --success: var(--color-success-dark);
    --info: var(--color-primary);

    --font-base-color: var(--color-primary);
    --nav-link-active-color: var(--background-color);
    --divider-color: var(--color-grey-2);

    --dark-input-color: var(--background-color);
    --dark-input-background-color: #17404f;

    --progress-bar-track-color: var(--color-terra-cotta);

    --scrollbar-background-color: var(--color-grey-2);
    --tab-link-active-color: var(--background-color);
    --tab-link-active-bg-color: var(--color-secondary);

    --card-background-color: var(--background-color);
    --card-border-color: var(--color-grey-1);

    ////////////////
    /// SPACINGS ///
    ////////////////

    --padding-xsm: 8px;
    --padding-sm: 16px;
    --padding-md: 20px;
    --padding-lg: 24px;
    --padding-xlg: 32px;

    ////////////
    /// MISC ///
    ////////////

    --internal-root-font-size: 14;

    //////////////////
    /// TYPOGRAPHY ///
    //////////////////

    /// Main Heading
    --typography-heading-main-font-family: Inter, sans-serif;
    --typography-heading-main-font-weight: 600;
    --typography-heading-main-font-size: 20; // unitless px
    --typography-heading-main-line-height: 26; // unitless px
    --typography-heading-main-letter-spacing: 0%;

    /// Sub Heading
    --typography-heading-sub-font-family: Inter, sans-serif;
    --typography-heading-sub-font-weight: 600;
    --typography-heading-sub-font-size: 14; // unitless px
    --typography-heading-sub-line-height: 18; // unitless px
    --typography-heading-sub-letter-spacing: 0%;

    /// Body (Default)
    --typography-body-font-family: Inter, sans-serif;
    --typography-body-font-weight: 400;
    --typography-body-font-size: 13; // unitless px
    --typography-body-line-height: 20; // unitless px
    --typography-body-letter-spacing: 0%;

    /// Table Column Header
    --typography-table-column-header-font-family: Inter, sans-serif;
    --typography-table-column-header-font-weight: 600;
    --typography-table-column-header-font-size: 12; // unitless px
    --typography-table-column-header-line-height: 18; // unitless px
    --typography-table-column-header-letter-spacing: 0%;

    /// Table Cell (Default)
    --typography-table-cell-font-family: Inter, sans-serif;
    --typography-table-cell-font-weight: 500;
    --typography-table-cell-font-size: 13; // unitless px
    --typography-table-cell-line-height: 20; // unitless px
    --typography-table-cell-letter-spacing: 0%;

    /// Table Cell (Small)
    --typography-table-cell-sm-font-family: Inter, sans-serif;
    --typography-table-cell-sm-font-weight: 500;
    --typography-table-cell-sm-font-size: 12; // unitless px
    --typography-table-cell-sm-line-height: 18; // unitless px
    --typography-table-cell-sm-letter-spacing: 0%;

    /// Button
    --typography-button-font-family: Inter, sans-serif;
    --typography-button-font-weight: 600;
    --typography-button-font-size: 14; // unitless px
    --typography-button-line-height: 17; // unitless px
    --typography-button-letter-spacing: 0%;

    /// Button (Small)
    --typography-button-sm-font-family: Inter, sans-serif;
    --typography-button-sm-font-weight: 600;
    --typography-button-sm-font-size: 12; // unitless px
    --typography-button-sm-line-height: 15; // unitless px
    --typography-button-sm-letter-spacing: 0%;

    /// Pill (Default)
    --typography-pill-font-family: Inter, sans-serif;
    --typography-pill-font-weight: 500;
    --typography-pill-font-size: 10.5; // unitless px
    --typography-pill-line-height: 13; // unitless px
    --typography-pill-letter-spacing: 0%;

    /// Modal Title
    --typography-modal-title-font-family: Inter, sans-serif;
    --typography-modal-title-font-weight: 600;
    --typography-modal-title-font-size: 16; // unitless px
    --typography-modal-title-line-height: 20; // unitless px
    --typography-modal-title-letter-spacing: 0%;

    /// Modal Headline
    --typography-modal-headline-font-family: Inter, sans-serif;
    --typography-modal-headline-font-weight: 600;
    --typography-modal-headline-font-size: 20; // unitless px
    --typography-modal-headline-line-height: 27; // unitless px
    --typography-modal-headline-letter-spacing: 0%;

    /// Modal Description
    --typography-modal-description-font-family: Inter, sans-serif;
    --typography-modal-description-font-weight: 400;
    --typography-modal-description-font-size: 14; // unitless px
    --typography-modal-description-line-height: 21; // unitless px
    --typography-modal-description-letter-spacing: 0%;

    /// Modal Mini Header

    /// Field Input (Default)
    --typography-field-input-font-family: Inter, sans-serif;
    --typography-field-input-font-weight: 500;
    --typography-field-input-font-size: 13; // unitless px
    --typography-field-input-line-height: 20; // unitless px
    --typography-field-input-letter-spacing: 0%;

    /// Field Label
    --typography-field-label-font-family: Inter, sans-serif;
    --typography-field-label-font-weight: 400;
    --typography-field-label-font-size: 12; // unitless px
    --typography-field-label-line-height: 15; // unitless px
    --typography-field-label-letter-spacing: 0%;

    /// Field List Item
    --typography-field-list-item-font-family: Inter, sans-serif;
    --typography-field-list-item-font-weight: 400;
    --typography-field-list-item-font-size: 12; // unitless px
    --typography-field-list-item-line-height: 15; // unitless px
    --typography-field-list-item-letter-spacing: 0%;

    /// Field Input Small
}
