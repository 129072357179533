/////////////////////////
/// BACKGROUND COLORS ///
/////////////////////////

.bg-primary {
    background-color: var(--color-primary);
}

.bg-primary-muted {
    background-color: var(--color-primary-muted);
}

.bg-secondary {
    background-color: var(--color-secondary);
}

.bg-accent {
    background-color: var(--color-accent);
}

.bg-background,
.bg {
    background-color: var(--color-background);
}

.bg-background-muted {
    background-color: var(--color-background-muted);
}

.bg-complete {
    background-color: var(--color-complete);
}

.bg-compliant {
    background-color: var(--color-compliant);
}

.bg-upcoming {
    background-color: var(--color-upcoming);
}

.bg-overdue {
    background-color: var(--color-overdue);
}

.bg-not-critical {
    background-color: var(--color-not-critical);
}

.bg-critical {
    background-color: var(--color-critical);
}

.bg-defect {
    background-color: var(--color-defect);
}

.bg-not-compliant {
    background-color: var(--color-not-compliant);
}

/////////////////////////
/// FOREGROUND COLORS ///
/////////////////////////

.text-primary {
    color: var(--color-primary);
}

.text-primary-muted {
    color: var(--color-primary-muted);
}

.text-secondary {
    color: var(--color-secondary);
}

.text-accent {
    color: var(--color-accent);
}

.text-input {
    color: var(--color-input);
}

.text-input-disabled {
    color: var(--color-input-disabled);
}

.text-input-label {
    color: var(--color-input-label);
}

.text-input-placeholder {
    color: var(--color-input-placeholder);
}

.text-complete {
    color: var(--color-complete);
}

.text-compliant {
    color: var(--color-compliant);
}

.text-upcoming {
    color: var(--color-upcoming);
}

.text-overdue {
    color: var(--color-overdue);
}

.text-not-critical {
    color: var(--color-not-critical);
}

.text-critical {
    color: var(--color-critical);
}

.text-defect {
    color: var(--color-defect);
}

.text-not-compliant {
    color: var(--color-not-compliant);
}

.text-on-primary,
.text-on-primary-muted,
.text-on-secondary,
.text-on-accent,
.text-on-compliant,
.text-on-overdue,
.text-on-critical,
.text-on-defect,
.text-on-not-compliant {
    color: var(--internal-white);
}

.text-on-background,
.text-on-background-muted,
.text-on-complete,
.text-on-upcoming,
.text-on-not-critical {
    color: var(--color-primary);
}

/////////////////////
/// BORDER COLORS ///
/////////////////////

.bdr-border,
.bdr {
    border-color: var(--color-border);
}
