$spacing-values: (0, 1, 2, 3, 4, 5, 6, 7, 8);

@mixin generate-spacing($property, $prefix) {
    @each $value in $spacing-values {
        .#{$prefix}-#{$value} {
            #{$property}: #{$value * 4px};
        }
        .\!#{$prefix}-#{$value} {
            #{$property}: #{$value * 4px} !important;
        }
    }
}

@mixin generate-directional-spacing($property, $prefix, $directions) {
    @each $direction, $suffix in $directions {
        @each $value in $spacing-values {
            .#{$prefix}#{$suffix}-#{$value} {
                #{$property}-#{$direction}: #{$value * 4px};
            }
            .\!#{$prefix}#{$suffix}-#{$value} {
                #{$property}-#{$direction}: #{$value * 4px} !important;
            }
        }
    }
}

@mixin generate-axis-spacing($property, $prefix) {
    @each $axis, $sides in $axis-directions {
        @each $value in $spacing-values {
            .#{$prefix}#{$axis}-#{$value} {
                @each $side in $sides {
                    #{$property}-#{$side}: #{$value * 4px};
                }
            }
            .\!#{$prefix}#{$axis}-#{$value} {
                @each $side in $sides {
                    #{$property}-#{$side}: #{$value * 4px} !important;
                }
            }
        }
    }
}

@mixin generate-special-spacing($property, $prefix) {
    .#{$prefix}-inherit {
        #{$property}: inherit;
    }
    .#{$prefix}-initial {
        #{$property}: initial;
    }
}

$directions: (
    top: 't',
    right: 'r',
    bottom: 'b',
    left: 'l',
);

$axis-directions: (
    x: (
        'left',
        'right',
    ),
    y: (
        'top',
        'bottom',
    ),
);

:where(._m-0) {
    margin: 0;
}

:where(._p-0) {
    padding: 0;
}

@include generate-special-spacing('margin', 'm');
@include generate-special-spacing('padding', 'p');

@include generate-spacing('padding', 'p');
@include generate-directional-spacing('padding', 'p', $directions);
@include generate-axis-spacing('padding', 'p');

@include generate-spacing('margin', 'm');
@include generate-directional-spacing('margin', 'm', $directions);
@include generate-axis-spacing('margin', 'm');

@include generate-spacing('gap', 'gap');
@include generate-axis-spacing('gap', 'gap');
