table {
    width: 100%;

    th.mat-mdc-header-cell {
        border-bottom-color: var(--color-grey-4);
    }

    th.text-center,
    td.text-center {
        text-align: center;

        .mat-sort-header-container {
            justify-content: center;
        }
    }

    th.text-right,
    td.text-right {
        text-align: right;
    }

    td.semi-bold {
        font-weight: 600;
    }

    td.primary {
        color: var(--color-primary);
    }

    tr.mat-mdc-row {
        color: var(--color-primary-light);
    }

    td.mat-mdc-cell.mdc-data-table__cell {
        border-bottom: none;
    }
}

.mat-sort-header.mat-mdc-header-cell.mdc-data-table__header-cell.cdk-header-cell,
.mat-mdc-header-cell.mdc-data-table__header-cell.cdk-header-cell {
    font-weight: 600;
}

table.bottom-border {
    tr:not(:last-child) {
        td.mat-mdc-cell.mdc-data-table__cell {
            border-bottom: 1px solid var(--color-grey-4);
        }
    }
}

table.padded {
    td.mat-mdc-cell.mdc-data-table__cell {
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

table.striped {
    --mat-table-row-item-container-height: 60px;

    tbody {
        tr:not(.last-child) {
            td.mat-mdc-cell.mdc-data-table__cell {
                border-bottom: 1px solid var(--color-grey-4);
            }
        }
    }

    tr.mat-mdc-row:hover {
        background-color: var(--color-grey-5);
    }
}

table.hover {
    --mat-table-row-item-container-height: 60px;

    tr.mat-mdc-row:hover {
        background-color: var(--color-grey-5);
    }
}
