/* Mat Checkbox */
.mat-primary {
    mat-option {
        .mat-pseudo-checkbox {
            width: 20px;
            height: 20px;
            border-width: 1px;
            border-color: var(--color-primary-light);
        }

        .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
            background-color: var(--background-color);
            border-color: var(--color-primary);

            &::after {
                color: var(--color-secondary);
            }
        }
    }

    mat-option.mat-mdc-option.mdc-list-item--selected:not(
            .mdc-list-item--disabled
        )
        .mdc-list-item__primary-text {
        color: var(--color-primary);
        font-weight: 600;
    }
}

mat-checkbox.rounded {
    .mdc-checkbox__background {
        border-radius: 50%;
    }

    &.mdc-checkbox--disabled.mat-mdc-checkbox-disabled:not(
            .mat-mdc-checkbox-checked
        ) {
        .mdc-checkbox__background {
            border-color: var(--color-grey-1) !important;
            background-color: var(--color-grey-1) !important;
        }

        .mdc-checkbox__checkmark {
            opacity: 1;
            --mdc-checkbox-disabled-selected-checkmark-color: var(
                --color-primary-light
            );

            .mdc-checkbox__checkmark-path {
                stroke-dashoffset: 0;
            }
        }
    }
}

mat-checkbox.rounded.mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-disabled-selected-icon-color: var(--color-secondary);
}

// TODO - remove this after QpxCheckbox is in use everywhere
mat-checkbox.mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-selected-checkmark-color: var(--background-color);
}

mat-checkbox.compact.mat-mdc-checkbox {
    height: 34px;
    .mdc-form-field {
        font-size: 13px;
    }
}

mat-checkbox.mat-primary.squared {
    .mdc-checkbox
        .mdc-checkbox__native-control:enabled:checked
        ~ .mdc-checkbox__background,
    .mdc-checkbox
        .mdc-checkbox__native-control:enabled:indeterminate
        ~ .mdc-checkbox__background,
    .mdc-checkbox
        .mdc-checkbox__native-control[data-indeterminate='true']:enabled
        ~ .mdc-checkbox__background {
        border-color: var(--color-primary-light);
        background-color: var(---background-color);
    }

    .mdc-checkbox
        .mdc-checkbox__native-control:enabled
        ~ .mdc-checkbox__background
        .mdc-checkbox__checkmark {
        color: var(--color-secondary);
    }

    .mdc-checkbox {
        width: 20px !important;
        height: 20px !important;
        flex: 0 0 20px !important;
    }

    .mdc-checkbox__background {
        width: 20px !important;
        height: 20px !important;
        border-width: 1px;
        border-color: var(--color-primary-light);

        svg {
            width: 14px;
            height: 14px;
            top: 2px;
            left: 2px;
        }
    }
}

mat-checkbox.mat-mdc-checkbox .mdc-checkbox__ripple {
    display: none !important;
}

.qpx-checkbox {
    --mat-checkbox-label-text-color: var(--color-input-label);
    --mat-checkbox-label-text-size: calc(
        var(--typography-table-cell-font-size) /
            var(--internal-root-font-size) * 1rem
    );
    --mat-checkbox-label-text-font: var(--typography-table-cell-font-family);
    --mat-checkbox-label-text-weight: var(--typography-table-cell-font-weight);
    --mat-checkbox-label-text-tracking: var(
        --typography-table-cell-letter-spacing
    );
    --mat-checkbox-label-text-line-height: calc(
        var(--typography-table-cell-line-height) /
            var(--internal-root-font-size) * 1rem
    );
    --mat-checkbox-disabled-label-color: var(--color-border);

    &.mat-primary {
        --mdc-checkbox-disabled-selected-checkmark-color: var(
            --color-background
        );
        --mdc-checkbox-disabled-selected-icon-color: var(--color-border);
        --mdc-checkbox-disabled-unselected-icon-color: var(--color-border);

        --mdc-checkbox-selected-icon-color: var(--color-primary);
        --mdc-checkbox-selected-hover-icon-color: var(--color-primary);
        --mdc-checkbox-selected-focus-icon-color: var(--color-primary);
        --mdc-checkbox-selected-pressed-icon-color: var(--color-primary);

        --mdc-checkbox-unselected-icon-color: var(--color-primary);
        --mdc-checkbox-unselected-hover-icon-color: var(--color-primary);
        --mdc-checkbox-unselected-focus-icon-color: var(--color-primary);

        .mdc-checkbox__native-control:enabled:checked
            ~ .mdc-checkbox__background,
        .mdc-checkbox__native-control:enabled:indeterminate
            ~ .mdc-checkbox__background,
        .mdc-checkbox__native-control[data-indeterminate='true']:enabled
            ~ .mdc-checkbox__background {
            border-color: var(--color-primary);
            background-color: var(--color-background);
        }

        &.mat-mdc-checkbox {
            --mdc-checkbox-selected-checkmark-color: var(--color-accent);
        }
    }

    &.mat-accent {
        --mdc-checkbox-disabled-selected-checkmark-color: var(
            --color-background
        );
        --mdc-checkbox-disabled-selected-icon-color: var(--color-border);
        --mdc-checkbox-disabled-unselected-icon-color: var(--color-border);

        --mdc-checkbox-selected-icon-color: var(--color-accent);
        --mdc-checkbox-selected-hover-icon-color: var(--color-accent);
        --mdc-checkbox-selected-focus-icon-color: var(--color-accent);
        --mdc-checkbox-selected-pressed-icon-color: var(--color-accent);

        --mdc-checkbox-unselected-icon-color: var(--color-border);
        --mdc-checkbox-unselected-hover-icon-color: var(--color-border);
        --mdc-checkbox-unselected-focus-icon-color: var(--color-border);
        --mdc-checkbox-unselected-focus-icon-color: var(--color-border);
    }

    &.mat-mdc-checkbox-checked {
        --mat-checkbox-label-text-color: var(--color-input);
    }

    &.square {
        .mdc-checkbox__background {
            border-width: 1px;

            svg {
                width: 14px;
                height: 14px;
                place-self: center;
            }
        }
    }
}
