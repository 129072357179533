:root {
    /* Colours */
    --color-primary: #103441;
    --color-primary-light: #758c95;
    --color-secondary: #02add7;
    --color-secondary-light: #e5f7fb;
    --color-success: #50cd88;
    --color-success-light: #b6e4ca;
    --color-success-dark: #47be7d;
    --color-danger: #ca0606;
    --color-danger-2: #b51849;
    --color-danger-light: #ed6e75;
    --color-grey-1: #d2dadf;
    --color-grey-2: #dfe4e8;
    --color-grey-3: #666666;
    --color-grey-4: #f3f5f7;
    --color-grey-5: #fafafa;
    --color-grey-6: #f1f1f2;
    --color-grey-7: #eaeaea;
    --color-grey-8: #9fafb5;
    --color-green-light: #e0fbef;
    --color-red-light: #ffeded;
    --color-blue: #8ecbdd;
    --color-blue-2: #7092be;
    --color-blue-3: #8fb0ff;
    --color-blue-light: #ebf9fc;
    --color-yellow: #eac137;
    --color-orange: #de8013;
    --color-orange-light: #faecdc;
    --color-terra-cotta: #ea6a5e;
    --color-purple: #cabeff;

    --background-color: #ffffff;
    --scrollbar-color: var(--color-grey-6);

    --status-completed-bg: var(--color-success-light);
    --status-overdue-bg: var(--color-danger-light);
    --status-upcoming-bg: var(--color-yellow);
    --status-not-started-bg: var(--color-primary-light);
    --status-compliant-bg: var(--color-secondary);
    --status-not-compliant-bg: var(--color-primary);
    --status-critical-bg: var(--color-orange);
    --status-non-critical-bg: var(--color-grey-4);

    --warning: var(--color-yellow);
    --danger: var(--color-danger);
    --success: var(--color-success-dark);
    --info: var(--color-primary);

    --font-base-color: var(--color-primary);
    --nav-link-active-color: var(--background-color);
    --nav-link-active-color: var(--background-color);
    --divider-color: var(--color-grey-2);

    --dark-input-color: var(--background-color);
    --dark-input-background-color: #17404f;

    --progress-bar-track-color: var(--color-terra-cotta);

    --scrollbar-background-color: var(--color-grey-2);
    --tab-link-active-color: var(--background-color);
    --tab-link-active-bg-color: var(--color-secondary);

    --card-background-color: var(--background-color);
    --card-border-color: var(--color-grey-1);

    // paddings
    --padding-xsm: 8px;
    --padding-sm: 16px;
    --padding-md: 20px;
    --padding-lg: 24px;
    --padding-xlg: 32px;
}

$media-sm-min: 576px;
$media-md-min: 768px;
$media-lg-min: 992px;
$media-xlg-min: 1200px;
$media-xxlg-min: 1400px;
