/* Mat Tabs */
mat-tab-group.maintenance {
    .mat-mdc-tab-labels {
        .mat-mdc-tab {
            border-top: 1px solid var(--color-grey-1);
            border-left: 1px solid var(--color-grey-1);
        }

        .mat-mdc-tab:first-child {
            border-top-left-radius: 5px;
        }

        .mat-mdc-tab:last-child {
            border-top-right-radius: 5px;
            border-right: 1px solid var(--color-grey-1);
        }
    }

    .mat-mdc-tab-body-wrapper {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        border: 1px solid var(--color-grey-1);
        margin-top: -1px;
    }

    .mdc-tab {
        background-color: var(--color-grey-6);
    }

    .mdc-tab--active {
        background-color: var(--background-color);
    }

    .mdc-tab-indicator {
        display: none;
    }

    mat-tab-body {
        height: 100%;
    }
}

mat-tab-group.mat-primary {
    .mdc-tab.mat-mdc-tab:not(.mdc-tab-indicator--active) {
        .mdc-tab-indicator__content {
            opacity: 1 !important;
            border-color: var(--color-grey-7) !important;
        }
    }

    .mdc-tab.mat-mdc-tab.mdc-tab-indicator--active {
        --mat-tab-header-active-label-text-color: var(--color-primary);
        --mat-tab-header-active-hover-label-text-color: var(--color-primary);
        --mat-tab-header-active-focus-label-text-color: var(--color-primary);
    }

    .mdc-tab__ripple,
    .mdc-tab-indicator {
        background-color: var(--background-color);
    }
}

mat-tab-group.full-width {
    .mat-mdc-tab.mdc-tab {
        flex-grow: 1;
    }
}
