mat-snack-bar-container.success {
    --mdc-snackbar-container-color: var(--success);
    --mdc-snackbar-supporting-text-color: var(--background-color);
}

mat-snack-bar-container.danger {
    --mdc-snackbar-container-color: var(--danger);
    --mdc-snackbar-supporting-text-color: var(--background-color);
}

mat-snack-bar-container {
    .mat-mdc-button .mdc-button__label {
        font-family: 'Material Icons';
        font-size: 29px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        color: var(--background-color);
    }
}
