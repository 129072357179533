.qpx-font-heading-main {
    font-family: var(--typography-heading-main-font-family);
    font-weight: var(--typography-heading-main-font-weight);
    font-size: calc(
        var(--typography-heading-main-font-size) /
            var(--internal-root-font-size) * 1rem
    );
    line-height: calc(
        var(--typography-heading-main-line-height) /
            var(--internal-root-font-size) * 1rem
    );
    letter-spacing: var(--typography-heading-main-letter-spacing);
}

.qpx-font-heading-sub {
    font-family: var(--typography-heading-sub-font-family);
    font-weight: var(--typography-heading-sub-font-weight);
    font-size: calc(
        var(--typography-heading-sub-font-size) /
            var(--internal-root-font-size) * 1rem
    );
    line-height: calc(
        var(--typography-heading-sub-line-height) /
            var(--internal-root-font-size) * 1rem
    );
    letter-spacing: var(--typography-heading-sub-letter-spacing);
}

.qpx-font-body {
    font-family: var(--typography-body-font-family);
    font-weight: var(--typography-body-font-weight);
    font-size: calc(
        var(--typography-body-font-size) / var(--internal-root-font-size) * 1rem
    );
    line-height: calc(
        var(--typography-body-line-height) / var(--internal-root-font-size) *
            1rem
    );
    letter-spacing: var(--typography-body-letter-spacing);
}

.qpx-font-table-column-header {
    font-family: var(--typography-table-column-header-font-family);
    font-weight: var(--typography-table-column-header-font-weight);
    font-size: calc(
        var(--typography-table-column-header-font-size) /
            var(--internal-root-font-size) * 1rem
    );
    line-height: calc(
        var(--typography-table-column-header-line-height) /
            var(--internal-root-font-size) * 1rem
    );
    letter-spacing: var(--typography-table-column-header-letter-spacing);
}

.qpx-font-table-cell {
    font-family: var(--typography-table-cell-font-family);
    font-weight: var(--typography-table-cell-font-weight);
    font-size: calc(
        var(--typography-table-cell-font-size) /
            var(--internal-root-font-size) * 1rem
    );
    line-height: calc(
        var(--typography-table-cell-line-height) /
            var(--internal-root-font-size) * 1rem
    );
    letter-spacing: var(--typography-table-cell-letter-spacing);
}

.qpx-font-table-cell-sm {
    font-family: var(--typography-table-cell-sm-font-family);
    font-weight: var(--typography-table-cell-sm-font-weight);
    font-size: calc(
        var(--typography-table-cell-sm-font-size) /
            var(--internal-root-font-size) * 1rem
    );
    line-height: calc(
        var(--typography-table-cell-sm-line-height) /
            var(--internal-root-font-size) * 1rem
    );
    letter-spacing: var(--typography-table-cell-sm-letter-spacing);
}

.qpx-font-button {
    font-family: var(--typography-button-font-family);
    font-weight: var(--typography-button-font-weight);
    font-size: calc(
        var(--typography-button-font-size) / var(--internal-root-font-size) *
            1rem
    );
    line-height: calc(
        var(--typography-button-line-height) / var(--internal-root-font-size) *
            1rem
    );
    letter-spacing: var(--typography-button-letter-spacing);
}

.qpx-font-button-sm {
    font-family: var(--typography-button-sm-font-family);
    font-weight: var(--typography-button-sm-font-weight);
    font-size: calc(
        var(--typography-button-sm-font-size) / var(--internal-root-font-size) *
            1rem
    );
    line-height: calc(
        var(--typography-button-sm-line-height) /
            var(--internal-root-font-size) * 1rem
    );
    letter-spacing: var(--typography-button-sm-letter-spacing);
}

.qpx-font-pill {
    font-family: var(--typography-pill-font-family);
    font-weight: var(--typography-pill-font-weight);
    font-size: calc(
        var(--typography-pill-font-size) / var(--internal-root-font-size) * 1rem
    );
    line-height: calc(
        var(--typography-pill-line-height) / var(--internal-root-font-size) *
            1rem
    );
    letter-spacing: var(--typography-pill-letter-spacing);
}

.qpx-font-modal-title {
    font-family: var(--typography-modal-title-font-family);
    font-weight: var(--typography-modal-title-font-weight);
    font-size: calc(
        var(--typography-modal-title-font-size) /
            var(--internal-root-font-size) * 1rem
    );
    line-height: calc(
        var(--typography-modal-title-line-height) /
            var(--internal-root-font-size) * 1rem
    );
    letter-spacing: var(--typography-modal-title-letter-spacing);
}

.qpx-font-modal-headline {
    font-family: var(--typography-modal-headline-font-family);
    font-weight: var(--typography-modal-headline-font-weight);
    font-size: calc(
        var(--typography-modal-headline-font-size) /
            var(--internal-root-font-size) * 1rem
    );
    line-height: calc(
        var(--typography-modal-headline-line-height) /
            var(--internal-root-font-size) * 1rem
    );
    letter-spacing: var(--typography-modal-headline-letter-spacing);
}

.qpx-font-modal-description {
    font-family: var(--typography-modal-description-font-family);
    font-weight: var(--typography-modal-description-font-weight);
    font-size: calc(
        var(--typography-modal-description-font-size) /
            var(--internal-root-font-size) * 1rem
    );
    line-height: calc(
        var(--typography-modal-description-line-height) /
            var(--internal-root-font-size) * 1rem
    );
    letter-spacing: var(--typography-modal-description-letter-spacing);
}

// .qpx-font-field-input is also incorporated into .qpx-input in form.scss
.qpx-font-field-input {
    font-family: var(--typography-field-input-font-family);
    font-weight: var(--typography-field-input-font-weight);
    font-size: calc(
        var(--typography-field-input-font-size) /
            var(--internal-root-font-size) * 1rem
    );
    line-height: calc(
        var(--typography-field-input-line-height) /
            var(--internal-root-font-size) * 1rem
    );
    letter-spacing: var(--typography-field-input-letter-spacing);
}

// .qpx-font-field-label is also incorporated into .qpx-input in form.scss
.qpx-font-field-label {
    font-family: var(--typography-field-label-font-family);
    font-weight: var(--typography-field-label-font-weight);
    font-size: calc(
        var(--typography-field-label-font-size) /
            var(--internal-root-font-size) * 1rem
    );
    line-height: calc(
        var(--typography-field-label-line-height) /
            var(--internal-root-font-size) * 1rem
    );
    letter-spacing: var(--typography-field-label-letter-spacing);
}
