/* container */
.flex {
    display: flex;
}

/* direction */
.flex-col {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.flex-col-r,
.flex-col-reverse {
    flex-direction: column-reverse;
}

.flex-row-r,
.flex-row-reverse {
    flex-direction: row-reverse;
}

/* wrap */
.flex.nowrap,
.flex-nowrap {
    flex-wrap: nowrap;
}

.flex.wrap,
.flex-wrap {
    flex-wrap: wrap;
}

.flex.wrap-reverse,
.flex-wrap-reverse {
    flex-wrap: wrap;
}

/* main axis */
.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.justify-evenly {
    justify-content: space-evenly;
}

/* cross axis */
.align-start,
.items-start {
    align-items: flex-start;
}

.align-end,
.items-end {
    align-items: flex-end;
}

.align-center,
.items-center {
    align-items: center;
}

.align-baseline,
.items-baseline {
    align-items: baseline;
}

.align-stretch,
.items-stretch {
    align-items: stretch;
}

// align self
.self-auto {
    align-self: auto;
}
.self-start {
    align-self: flex-start;
}
.self-end {
    align-self: flex-end;
}
.self-center {
    align-self: center;
}
.self-stretch {
    align-self: stretch;
}
.self-baseline {
    align-self: baseline;
}

// place self
.place-self-center {
    place-self: center;
}

/* grow/shrink */
.flex-grow,
.grow {
    flex-grow: 1;
}

.flex-shrink,
.shrink {
    flex-shrink: 1;
}

.flex-gap-sm {
    gap: 16px;
}

.flex-gap-md {
    gap: 20px;
}

.flex-gap-lg {
    gap: 24px;
}
