/* Mat Checkbox */
.mat-primary {
    mat-option {
        .mat-pseudo-checkbox {
            width: 20px;
            height: 20px;
            border-width: 1px;
            border-color: var(--color-primary-light);
        }

        .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
            background-color: var(--background-color);
            border-color: var(--color-primary);

            &::after {
                color: var(--color-secondary);
            }
        }
    }

    mat-option.mat-mdc-option.mdc-list-item .mdc-list-item__primary-text {
        color: var(--color-primary-light);
    }

    mat-option.mat-mdc-option.mdc-list-item--selected:not(
            .mdc-list-item--disabled
        )
        .mdc-list-item__primary-text {
        color: var(--color-primary);
        font-weight: 600;
    }
}

mat-checkbox.rounded {
    .mdc-checkbox__background {
        border-radius: 50%;
    }

    &.mdc-checkbox--disabled.mat-mdc-checkbox-disabled:not(
            .mat-mdc-checkbox-checked
        ) {
        .mdc-checkbox__background {
            border-color: var(--color-grey-1) !important;
            background-color: var(--color-grey-1) !important;
        }

        .mdc-checkbox__checkmark {
            opacity: 1;
            --mdc-checkbox-disabled-selected-checkmark-color: var(
                --color-primary-light
            );

            .mdc-checkbox__checkmark-path {
                stroke-dashoffset: 0;
            }
        }
    }
}

mat-checkbox.rounded.mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-disabled-selected-icon-color: var(--color-secondary);
}

mat-checkbox.mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-selected-checkmark-color: var(--background-color);
}

mat-checkbox.compact.mat-mdc-checkbox {
    height: 34px;
    .mdc-form-field {
        font-size: 13px;
    }
}

mat-checkbox.mat-primary.squared {
    .mdc-checkbox
        .mdc-checkbox__native-control:enabled:checked
        ~ .mdc-checkbox__background,
    .mdc-checkbox
        .mdc-checkbox__native-control:enabled:indeterminate
        ~ .mdc-checkbox__background,
    .mdc-checkbox
        .mdc-checkbox__native-control[data-indeterminate='true']:enabled
        ~ .mdc-checkbox__background {
        border-color: var(--color-primary-light);
        background-color: var(---background-color);
    }

    .mdc-checkbox
        .mdc-checkbox__native-control:enabled
        ~ .mdc-checkbox__background
        .mdc-checkbox__checkmark {
        color: var(--color-secondary);
    }

    .mdc-checkbox {
        width: 20px !important;
        height: 20px !important;
        flex: 0 0 20px !important;
    }

    .mdc-checkbox__background {
        width: 20px !important;
        height: 20px !important;
        border-width: 1px;
        border-color: var(--color-primary-light);

        svg {
            width: 14px;
            height: 14px;
            top: 2px;
            left: 2px;
        }
    }
}

mat-checkbox.mat-mdc-checkbox .mdc-checkbox__ripple {
    display: none !important;
}
