/* Mat Autocomplete */
.mat-mdc-autocomplete-panel {
    --mat-autocomplete-background-color: var(--background-color);
}

.mat-mdc-autocomplete-panel.building-search-autocomplete {
    width: 425px;
    height: 350px;
    max-height: 350px;
    box-shadow: none;
    margin: 24px -24px 0 -24px;

    .mat-mdc-option {
        padding: 12px 8px;
        margin-left: 24px;
        margin-right: 24px;

        &.mdc-list-item--disabled .mdc-list-item__primary-text {
            opacity: 1;
        }
    }
}

.mat-mdc-autocomplete-panel.tags-autocomplete {
    .mat-mdc-option {
        .mdc-list-item__primary-text {
            display: flex;
            flex-grow: 1;
        }
    }
}

.cdk-overlay-pane.building-search-autocomplete {
    height: 330px;
    max-height: 330px;
}

.mat-mdc-autocomplete-panel.assign-to-autocomplete {
    padding: 0;
    box-shadow: none;
    border: 1px solid var(--color-grey-1);
    max-height: 150px;
    overflow: auto;
}

.mat-mdc-autocomplete-panel.esm-autocomplete {
    padding: 0;
    max-height: 200px;
    overflow: auto;
}
