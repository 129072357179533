/* For use in src/lib/core/theming/_palette.scss */
$mat-palette-red: (
    50: #fde8ed,
    100: #fbc6d3,
    200: #f8a0b6,
    300: #f57a98,
    400: #f35e82,
    500: #f1416c,
    600: #ef3b64,
    700: #ed3259,
    800: #eb2a4f,
    900: #e71c3d,
    A100: #ffffff,
    A200: #ffe8eb,
    A400: #ffb5bf,
    A700: #ff9ca9,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
