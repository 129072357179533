/* For use in src/lib/core/theming/_palette.scss */
$mat-palette-black: (
    50: #fdfdfd,
    100: #fafafa,
    200: #f7f7f7,
    300: #f3f3f3,
    400: #f1f1f1,
    500: #eeeeee,
    600: #ececec,
    700: #e9e9e9,
    800: #e7e7e7,
    900: #e2e2e2,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
