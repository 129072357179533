/* Mat Form Field */
mat-form-field {
    &.w-100 {
        width: 100% !important;
    }

    &.no-error {
        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }

    &.search {
        .mat-mdc-form-field-infix {
            display: flex;
        }
    }

    &.mdc-notched-outline,
    .mdc-notched-outline__notch {
        display: none;
    }

    &.mat-form-field-hide-placeholder .mat-mdc-select-placeholder {
        color: var(--color-primary) !important;
        -webkit-text-fill-color: var(--color-primary) !important;
    }
}

mat-form-field.mat-form-field-disabled {
    cursor: not-allowed !important;

    mat-datepicker-toggle {
        .mat-mdc-icon-button:disabled {
            --mdc-icon-button-disabled-icon-color: var(--color-grey-1);
        }
    }
}

mat-form-field.disabled-dark {
    --mdc-outlined-text-field-disabled-outline-color: rgb(210, 218, 223);
    --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.87);
    --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.87);
    --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.54);

    mat-datepicker-toggle {
        .mat-mdc-icon-button:disabled {
            --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.54);
        }
    }
}

mat-form-field.mat-form-field-disabled.icon-free {
    mat-select {
        .mat-mdc-select-arrow-wrapper {
            display: none;
        }
    }

    mat-datepicker-toggle {
        .mat-mdc-icon-button {
            display: none !important;
        }
    }
}

mat-form-field.building-search-container {
    .mat-mdc-text-field-wrapper {
        height: 100%;
        .mat-mdc-form-field-infix {
            padding-top: 0rem;
        }
    }
}

mat-form-field.dark {
    border-radius: 5px;
    background-color: var(--dark-input-background-color);
    --mat-select-placeholder-text-color: var(--dark-input-color);

    &.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
        --mat-select-focused-arrow-color: var(--dark-input-color);
    }

    .mdc-text-field--outlined .mdc-notched-outline {
        display: none;
    }

    .mat-mdc-select {
        --mat-select-enabled-trigger-text-color: var(--dark-input-color);
        --mat-select-enabled-arrow-color: var(--dark-input-color);
    }

    &.mat-form-field-hide-placeholder .mat-mdc-select-placeholder {
        color: var(--dark-input-color) !important;
        -webkit-text-fill-color: var(--dark-input-color) !important;
    }

    &.filter-form-field {
        mat-select.mat-mdc-select .mat-mdc-select-placeholder {
            color: var(--dark-input-color) !important;
        }
    }
}

/* Mat Form Field Input */
mat-form-field:not(.mat-form-field-invalid) {
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__trailing {
        border-color: var(--card-border-color) !important;
    }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-width: 1px !important;
}

mat-form-field.mat-mdc-form-field.focused {
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__trailing,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__leading {
        border-color: var(--color-secondary) !important;
        border-width: 2px !important;
    }
}

mat-form-field.mat-mdc-form-field.focused-primary {
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__trailing,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__leading {
        border-color: var(--color-primary) !important;
        border-width: 2px !important;
    }
}

mat-form-field.mat-mdc-form-field.assign-to-form-filed {
    .mdc-notched-outline__leading {
        border-bottom-left-radius: 0;
    }

    .mdc-notched-outline__trailing {
        border-bottom-right-radius: 0;
    }
}

mat-form-field.mat-mdc-form-field.esm-autocomplete {
    background-color: var(--background-color);

    .mat-mdc-form-field-flex {
        height: 36px;
    }

    .mdc-text-field--outlined .mat-mdc-form-field-infix,
    .mdc-text-field--no-label .mat-mdc-form-field-infix {
        padding: 6px 0;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__trailing {
        border-color: var(--color-secondary) !important;
    }

    .mat-mdc-form-field-infix {
        width: auto;
    }
}

mat-form-field.mat-mdc-form-field.qpx-secondary-active {
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__trailing {
        border-color: var(--color-secondary) !important;
    }
}

/* Override mat-form-field with select */
mat-form-field.mat-mdc-form-field.qpx-select-slim {
    --mat-form-field-container-vertical-padding: 8px !important;

    .mat-mdc-text-field-wrapper {
        height: 40px !important;
    }
}

/* Override mat-form-field with select */
mat-form-field.mat-mdc-form-field.qpx-select-auto-width {
    .mat-mdc-select-arrow-wrapper {
        margin-left: 10px;
    }

    .mat-mdc-form-field-infix {
        width: auto !important;
    }
}

mat-form-field.mat-mdc-form-field.qpx-no-border {
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline
        .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline
        .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline
        .mdc-notched-outline__trailing {
        border-width: 0 !important;
    }
}
