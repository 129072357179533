@import './variables.scss';

.qpx-container {
    padding: var(--padding-md);
}

// card
.qpx-card {
    background-color: var(--card-background-color);
    border: 1px solid var(--card-border-color);
    border-radius: 5px;
    padding: var(--padding-lg);
}

.qpx-card-title {
    font-size: 1.375rem;
    font-weight: 700;
    padding-bottom: var(--padding-md);
}

.qpx-nav-link {
    color: var(--color-primary);
    font-size: 1.1rem;
    cursor: pointer;

    &.inactive {
        cursor: auto;
        text-decoration: none;
        color: var(--color-primary-light);
    }
}

.qpx-card-links {
    gap: 24px;
    padding-bottom: 20px;
    cursor: pointer;

    .card-link {
        font-size: 1.15rem;
        color: var(--color-primary);
        font-weight: 600;

        &.active {
            border-bottom: 2px solid var(--color-primary);
        }

        &.inactive {
            font-weight: 500;
            color: var(--color-primary-light);
        }
    }
}

.qpx-nav-link-item {
    text-decoration: underline;
}

.qpx-nav-link.inactive .qpx-nav-link-item {
    text-decoration: none;
}

.qpx-nav-link-icon {
    font-size: 1.6rem;
    color: var(--color-secondary);
}

.qpx-hr-line {
    opacity: 0.25;
    border: 0;
    border-top: 1px solid var(--divider-color);
    margin: 1rem 0;
}

.qpx-seperating-line {
    @extend .qpx-hr-line;
    margin: 0;
}

.qpx-spacer {
    width: 100%;
}

.qpx-header-title {
    font-weight: 600;
    font-size: 1.75rem;
    padding: 0 var(--padding-lg);
    margin: 0 0 10px 0;
}

// percentage
.qpx-percentage {
    display: flex;
    align-items: flex-start;
    line-height: 3rem;
    font-size: 3rem;
    font-weight: 600;
    color: var(--color-primary);
}

.qpx-percentage-sign {
    line-height: normal;
    font-size: 2rem;
}

.qpx-percentage-complete {
    display: flex;
    line-height: normal;
    align-self: end;
    font-size: 0.92rem; //12px
    font-weight: 600;
    padding-left: 8px;
    padding-bottom: 4px;
}

// end of percentage

// progress-bar
.qpx-progress {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 1;
}

.qpx-progress-label {
    color: var(--color-primary-light);
    font-size: 0.84rem;
}

.qpx-progress-item {
    width: 100%;
    height: 20px;
    accent-color: rgb(02, 147, 208) !important;
}

// end of progress

.qpx-text-success {
    color: var(--color-success);
}

.qpx-text-danger {
    color: var(--color-danger);
}

.qpx-loader-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.qpx-pill {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 5px;

    &:where(.md) {
        padding: 0 10px;
        height: 24px;
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: calc(10.5 / 14 * 1rem);
        line-height: calc(13 / 14 * 1rem);
        letter-spacing: 0%;
        text-align: center;
    }
}

////////////
/// FONT ///
////////////

.strong,
.font-bold {
    font-weight: 700;
}

.semi-bold,
.font-semibold {
    font-weight: 600;
}

.font-medium {
    font-weight: 500;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.w-100,
.w-full {
    width: 100%;
}

.h-full {
    width: 100%;
}

select {
    cursor: pointer;
    font-size: 1rem;
}

:where(button.unstyled),
:where(a.unstyled),
:where(.button.unstyled) {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    color: inherit;
    cursor: pointer;
    text-decoration: none;

    &:disabled {
        cursor: not-allowed;
    }

    &.link {
        text-decoration: underline;
        text-underline-offset: 2px;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.invisible {
    visibility: hidden;
}

.contents {
    display: contents;
}

.hidden {
    display: none;
}

.\!hidden {
    display: none !important;
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.grid {
    display: grid;
}

.border {
    border-width: 1px;
    border-style: solid;
}

.border-b {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.whitespace-pre-line {
    white-space: pre-line;
}

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}
.\!cursor-pointer {
    cursor: pointer !important;
}

.cursor-not-allowed {
    cursor: not-allowed;
}
.\!cursor-not-allowed {
    cursor: not-allowed !important;
}

.align-top {
    vertical-align: top;
}

.align-middle {
    vertical-align: middle;
}

.leading-0 {
    line-height: 0;
}
