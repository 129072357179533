/* Mat Form Field */
mat-form-field {
    &.w-100 {
        width: 100% !important;
    }

    &.no-error {
        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }

    &.search {
        .mat-mdc-form-field-infix {
            display: flex;
        }
    }

    &.mdc-notched-outline,
    .mdc-notched-outline__notch {
        display: none;
    }

    &.mat-form-field-hide-placeholder .mat-mdc-select-placeholder {
        color: var(--color-primary) !important;
        -webkit-text-fill-color: var(--color-primary) !important;
    }
}

mat-form-field.mat-form-field-disabled {
    cursor: not-allowed !important;

    mat-datepicker-toggle {
        .mat-mdc-icon-button:disabled {
            --mdc-icon-button-disabled-icon-color: var(--color-grey-1);
        }
    }
}

mat-form-field.disabled-dark {
    --mdc-outlined-text-field-disabled-outline-color: rgb(210, 218, 223);
    --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.87);
    --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.87);
    --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.54);

    mat-datepicker-toggle {
        .mat-mdc-icon-button:disabled {
            --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.54);
        }
    }
}

mat-form-field.mat-form-field-disabled.icon-free {
    mat-select {
        .mat-mdc-select-arrow-wrapper {
            display: none;
        }
    }

    mat-datepicker-toggle {
        .mat-mdc-icon-button {
            display: none !important;
        }
    }
}

mat-form-field.building-search-container {
    .mat-mdc-text-field-wrapper {
        height: 100%;
        .mat-mdc-form-field-infix {
            padding-top: 0rem;
        }
    }
}

mat-form-field.dark {
    border-radius: 5px;
    background-color: var(--dark-input-background-color);
    --mat-select-placeholder-text-color: var(--dark-input-color);

    &.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
        --mat-select-focused-arrow-color: var(--dark-input-color);
    }

    .mdc-text-field--outlined .mdc-notched-outline {
        display: none;
    }

    .mat-mdc-select {
        --mat-select-enabled-trigger-text-color: var(--dark-input-color);
        --mat-select-enabled-arrow-color: var(--dark-input-color);
    }

    &.mat-form-field-hide-placeholder .mat-mdc-select-placeholder {
        color: var(--dark-input-color) !important;
        -webkit-text-fill-color: var(--dark-input-color) !important;
    }

    &.filter-form-field {
        mat-select.mat-mdc-select .mat-mdc-select-placeholder {
            color: var(--dark-input-color) !important;
        }
    }
}

/* Mat Form Field Input */
// TODO: remove this when QpxFormFieldInput and QpxInput are in use everywhere
mat-form-field:not(.mat-form-field-invalid) {
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__trailing {
        border-color: var(--card-border-color) !important;
    }
}

// TODO: remove this when QpxFormFieldInput and QpxInput are in use everywhere
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-width: 1px !important;
}

mat-form-field.mat-mdc-form-field.focused {
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__trailing,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__leading {
        border-color: var(--color-secondary) !important;
        border-width: 2px !important;
    }
}

mat-form-field.mat-mdc-form-field.focused-primary {
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__trailing,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__leading {
        border-color: var(--color-primary) !important;
        border-width: 2px !important;
    }
}

mat-form-field.mat-mdc-form-field.assign-to-form-filed {
    .mdc-notched-outline__leading {
        border-bottom-left-radius: 0;
    }

    .mdc-notched-outline__trailing {
        border-bottom-right-radius: 0;
    }
}

mat-form-field.mat-mdc-form-field.esm-autocomplete {
    background-color: var(--background-color);

    .mat-mdc-form-field-flex {
        height: 36px;
    }

    .mdc-text-field--outlined .mat-mdc-form-field-infix,
    .mdc-text-field--no-label .mat-mdc-form-field-infix {
        padding: 6px 0;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__trailing {
        border-color: var(--color-secondary) !important;
    }

    .mat-mdc-form-field-infix {
        width: auto;
    }
}

mat-form-field.mat-mdc-form-field.qpx-secondary-active {
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__trailing {
        border-color: var(--color-secondary) !important;
    }
}

/* Override mat-form-field with select */
mat-form-field.mat-mdc-form-field.qpx-select-slim {
    --mat-form-field-container-vertical-padding: 8px !important;

    .mat-mdc-text-field-wrapper {
        height: 40px !important;
    }
}

/* Override mat-form-field with select */
mat-form-field.mat-mdc-form-field.qpx-select-auto-width {
    .mat-mdc-select-arrow-wrapper {
        margin-left: 10px;
    }

    .mat-mdc-form-field-infix {
        width: auto !important;
    }
}

// TODO - remove this when QpxFormFieldInput and QpxInput are in use everywhere
mat-form-field.mat-mdc-form-field.qpx-no-border {
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline
        .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline
        .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline
        .mdc-notched-outline__trailing {
        border-width: 0 !important;
    }
}

.qpx-label {
    color: var(--color-input-label);
    font-family: var(--typography-field-label-font-family);
    font-weight: var(--typography-field-label-font-weight);
    font-size: calc(
        var(--typography-field-label-font-size) /
            var(--internal-root-font-size) * 1rem
    );
    line-height: calc(
        var(--typography-field-label-line-height) /
            var(--internal-root-font-size) * 1rem
    );
    letter-spacing: var(--typography-field-label-letter-spacing);
    margin-bottom: 8px;
    display: block;
}

.qpx-input {
    --mat-form-field-container-text-font: var(
        --typography-field-input-font-family
    );
    --mat-form-field-container-text-line-height: calc(
        var(--typography-field-input-line-height) /
            var(--internal-root-font-size) * 1rem
    );
    --mat-form-field-container-text-size: calc(
        var(--typography-field-input-font-size) /
            var(--internal-root-font-size) * 1rem
    );
    --mat-form-field-container-text-weight: var(
        --typography-field-input-font-weight
    );

    --mdc-outlined-text-field-input-text-color: var(--color-input);
    --mdc-outlined-text-field-input-text-placeholder-color: var(
        --color-input-placeholder
    );
    --mdc-outlined-text-field-outline-color: var(--color-border);
    --mdc-outlined-text-field-outline-width: 1px;
    --mdc-outlined-text-field-focus-outline-width: 1px;

    :where(.qpx-no-border) & {
        --mdc-outlined-text-field-outline-width: 0;
        --mdc-outlined-text-field-focus-outline-width: 0;
    }

    &:where(.input) {
        --mat-form-field-container-vertical-padding: 12px;
        --mat-form-field-container-height: 44px;
    }

    &:where(.search) {
        --mat-form-field-container-vertical-padding: 7px;
        --mat-form-field-container-height: 40px;
    }

    &:where(.select) {
        --mat-form-field-container-vertical-padding: 12px;
        --mat-form-field-container-height: 44px;

        --mat-select-enabled-trigger-text-color: var(--color-input);
        --mat-select-placeholder-text-color: var(--color-input-placeholder);

        --mat-select-trigger-text-font: var(
            --mat-form-field-container-text-font
        );
        --mat-select-trigger-text-line-height: var(
            --mat-form-field-container-text-line-height
        );
        --mat-select-trigger-text-size: var(
            --mat-form-field-container-text-size
        );
        --mat-select-trigger-text-weight: var(
            --mat-form-field-container-text-weight
        );

        .mat-mdc-select-arrow-wrapper {
            height: var(--mat-form-field-container-text-line-height);
        }

        .custom-arrow .mat-mdc-select-arrow-wrapper {
            width: 0;
            opacity: 0;
            height: var(--mat-form-field-container-text-line-height);
        }
    }

    // TODO: remove this when QpxFormFieldInput and QpxInput are in use everywhere and the rule in styles.scss is removed
    input::placeholder {
        color: var(--color-input-placeholder) !important;
    }

    // TODO: remove this when QpxFormFieldInput and QpxInput are in use everywhere and the rule in vendor.scss is removed
    mat-select.mat-mdc-select .mat-mdc-select-placeholder {
        color: var(--color-input-placeholder) !important;
    }
}

.qpx-button {
    &:where(.md) {
        // source values
        --internal-container-height: 40px;
        --internal-label-text-font: var(--typography-button-font-family);
        --internal-label-text-weight: var(--typography-button-font-weight);
        --internal-label-text-size: calc(
            var(--typography-button-font-size) /
                var(--internal-root-font-size) * 1rem
        );
        --internal-label-text-tracking: var(--typography-button-letter-spacing);

        // height
        --mdc-protected-button-container-height: var(
            --internal-container-height
        );
        --mdc-text-button-container-height: var(--internal-container-height);
        --mdc-filled-button-container-height: var(--internal-container-height);
        --mdc-outlined-button-container-height: var(
            --internal-container-height
        );

        // font family
        --mdc-protected-button-label-text-font: var(--internal-label-text-font);
        --mdc-text-button-label-text-font: var(--internal-label-text-font);
        --mdc-filled-button-label-text-font: var(--internal-label-text-font);
        --mdc-outlined-button-label-text-font: var(--internal-label-text-font);

        // font weight
        --mdc-protected-button-label-text-weight: var(
            --internal-label-text-weight
        );
        --mdc-text-button-label-text-weight: var(--internal-label-text-weight);
        --mdc-filled-button-label-text-weight: var(
            --internal-label-text-weight
        );
        --mdc-outlined-button-label-text-weight: var(
            --internal-label-text-weight
        );

        // font size
        --mdc-protected-button-label-text-size: var(--internal-label-text-size);
        --mdc-text-button-label-text-size: var(--internal-label-text-size);
        --mdc-filled-button-label-text-size: var(--internal-label-text-size);
        --mdc-outlined-button-label-text-size: var(--internal-label-text-size);

        // letter spacing
        --mdc-protected-button-label-text-tracking: var(
            --internal-label-text-tracking
        );
        --mdc-text-button-label-text-tracking: var(
            --internal-label-text-tracking
        );
        --mdc-filled-button-label-text-tracking: var(
            --internal-label-text-tracking
        );
        --mdc-outlined-button-label-text-tracking: var(
            --internal-label-text-tracking
        );

        // line height
        &.mdc-button {
            line-height: calc(
                var(--typography-button-line-height) /
                    var(--internal-root-font-size) * 1rem
            );
        }
    }

    &:where(.sm) {
        // source values
        --internal-container-height: 32px;
        --internal-label-text-font: var(--typography-button-sm-font-family);
        --internal-label-text-weight: var(--typography-button-sm-font-weight);
        --internal-label-text-size: calc(
            var(--typography-button-sm-font-size) /
                var(--internal-root-font-size) * 1rem
        );
        --internal-label-text-tracking: var(
            --typography-button-sm-letter-spacing
        );
        --internal-horizontal-padding: 12px;

        // container height
        --mdc-protected-button-container-height: var(
            --internal-container-height
        );
        --mdc-text-button-container-height: var(--internal-container-height);
        --mdc-filled-button-container-height: var(--internal-container-height);
        --mdc-outlined-button-container-height: var(
            --internal-container-height
        );

        // font family
        --mdc-protected-button-label-text-font: var(--internal-label-text-font);
        --mdc-text-button-label-text-font: var(--internal-label-text-font);
        --mdc-filled-button-label-text-font: var(--internal-label-text-font);
        --mdc-outlined-button-label-text-font: var(--internal-label-text-font);

        // font weight
        --mdc-protected-button-label-text-weight: var(
            --internal-label-text-weight
        );
        --mdc-text-button-label-text-weight: var(--internal-label-text-weight);
        --mdc-filled-button-label-text-weight: var(
            --internal-label-text-weight
        );
        --mdc-outlined-button-label-text-weight: var(
            --internal-label-text-weight
        );

        // font size
        --mdc-protected-button-label-text-size: var(--internal-label-text-size);
        --mdc-text-button-label-text-size: var(--internal-label-text-size);
        --mdc-filled-button-label-text-size: var(--internal-label-text-size);
        --mdc-outlined-button-label-text-size: var(--internal-label-text-size);

        // letter spacing
        --mdc-protected-button-label-text-tracking: var(
            --internal-label-text-tracking
        );
        --mdc-text-button-label-text-tracking: var(
            --internal-label-text-tracking
        );
        --mdc-filled-button-label-text-tracking: var(
            --internal-label-text-tracking
        );
        --mdc-outlined-button-label-text-tracking: var(
            --internal-label-text-tracking
        );

        // horizontal padding
        --mat-protected-button-horizontal-padding: var(
            --internal-horizontal-padding
        );
        --mat-text-button-horizontal-padding: var(
            --internal-horizontal-padding
        );
        --mat-filled-button-horizontal-padding: var(
            --internal-horizontal-padding
        );
        --mat-outlined-button-horizontal-padding: var(
            --internal-horizontal-padding
        );

        // line height
        &.mdc-button {
            line-height: calc(
                var(--typography-button-sm-line-height) /
                    var(--internal-root-font-size) * 1rem
            );
        }
    }
}
