/* For use in src/lib/core/theming/_palette.scss */
$mat-palette-blue: (
    50: #e1f5fa,
    100: #b3e6f3,
    200: #81d6eb,
    300: #4ec6e3,
    400: #28b9dd,
    500: #02add7,
    600: #02a6d3,
    700: #019ccd,
    800: #0193c7,
    900: #0183be,
    A100: #e6f6ff,
    A200: #b3e4ff,
    A400: #80d2ff,
    A700: #67c9ff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
