/* Mat Dialog */
$_dialog-width-sm: 460px;

.mat-mdc-dialog-container .mdc-dialog__content {
    position: relative;
    --mdc-dialog-supporting-text-color: var(--color-primary-light);

    .loader-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 1000;
        background-color: rgba(255, 255, 255, 0.5);
    }
}

mat-dialog-container.mat-mdc-dialog-container .mdc-dialog__surface {
    --mdc-dialog-container-shape: 5px;
}

.qpx-dialog {
    width: 100%;
    max-width: 1000px !important;
    margin: 24px;

    .mat-mdc-dialog-container .mdc-dialog__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 24px;
        background-color: var(--color-primary);
        --mdc-dialog-subhead-color: var(--background-color);
        --mdc-dialog-subhead-size: 1.2rem;

        mat-icon {
            cursor: pointer;
        }
    }

    .mat-mdc-dialog-container .mdc-dialog__content {
        padding: 32px 24px 24px 24px !important;
    }

    .mat-mdc-dialog-content {
        height: 700px;
    }

    mat-dialog-actions {
        justify-content: flex-end;
        gap: 8px;
        border-top: 1px solid var(--color-grey-2);
        padding: 16px 24px;

        button.mdc-button {
            min-width: 100px;
        }
    }

    &:is(
            .add-contractor,
            .edit-requirements,
            .upload-compliance-document,
            .add-contractor-employee
        ) {
        max-width: $_dialog-width-sm !important;

        .mat-mdc-dialog-content {
            display: flex;
            flex-direction: column;
            height: auto;
            min-height: 500px;
            overflow: hidden;
            padding: 0 !important;
        }
    }

    &:is(
            .add-contractor,
            .upload-compliance-document,
            .add-contractor-employee
        ) {
        .dialog-content {
            padding: 32px 28px;
            overflow: hidden;
        }
    }

    &.building-manual {
        .mat-mdc-dialog-content {
            display: flex;
            flex-direction: column;
            height: auto;
            min-height: 500px;
            overflow: hidden;
            padding: 0 !important;
        }

        .dialog-content {
            padding: 32px 24px 24px 24px;
            overflow: auto;
        }
    }

    &:is(
            .esm-maintenance-update,
            .add-organisation,
            .add-user,
            .add-key,
            .manage-access,
            .edit-document
        ) {
        max-width: $_dialog-width-sm !important;

        .mat-mdc-dialog-content {
            height: auto;
        }
    }

    &:is(.esm-schedule, .add-unit-owner) {
        max-width: 860px !important;

        .mat-mdc-dialog-content {
            height: auto;
        }
    }
}

.qpx-dialog.add-document {
    .mat-mdc-dialog-content {
        height: auto;
    }
}

.qpx-dialog.add-contact {
    width: 555px;
    mat-dialog-content {
        height: 495px;
    }

    .mat-mdc-dialog-container .mdc-dialog__content {
        padding-top: 22px !important;
    }
}

.qpx-dialog.document-preview {
    max-width: 1200px !important;

    mat-dialog-actions {
        display: none;
    }

    .mat-mdc-dialog-content.mdc-dialog__content {
        padding: 0 !important;
        height: 100%;
        max-height: none;
        overflow-y: hidden;
    }
}

.qpx-dialog.edit-building-details {
    max-width: 600px !important;
}

.qpx-dialog.edit-key-documents {
    max-width: 500px !important;

    mat-dialog-content {
        max-height: 500px;
    }
}

.qpx-dialog.add-statement {
    max-width: 600px !important;

    mat-dialog-content {
        height: auto;
    }
}

.qpx-dialog.add-task,
.qpx-dialog.update-task {
    max-width: 500px !important;

    mat-dialog-content {
        height: auto;
    }
}

.fullscreen {
    width: 100%;
    max-width: 1000vw !important;
    margin: 0;

    mat-dialog-container.mat-mdc-dialog-container .mdc-dialog__surface {
        --mdc-dialog-container-shape: 0;
    }

    .mdc-dialog__container {
        height: 100vh;
        width: 100vw;
    }

    .mat-mdc-dialog-content {
        max-height: calc(
            100vh - 142px
        ); // modal header + footer (action-buttons)
        height: 100%;
    }
}

.confirm-dialog {
    .mat-mdc-dialog-container {
        width: 360px;
        text-align: center;
    }

    .mat-mdc-dialog-title {
        text-align: right;

        mat-icon {
            cursor: pointer;
        }
    }

    mat-dialog-actions {
        justify-content: center;
        gap: 8px;
        padding-bottom: 32px;

        button {
            min-width: 115px;
        }
    }

    .confirm-dialog-title {
        color: var(--color-primary);
        font-size: 1.3rem;
        font-weight: 600;
        padding-bottom: 16px;
    }
}
