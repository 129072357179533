// base
@import './app/styles/theme.scss';
@import './app/styles/variables.scss';
// typography
@import './app/styles/typography.scss';
// vendor overrides
@import './app/styles/vendor.scss';
@import './app/styles/autocomplete.scss';
@import './app/styles/form.scss';
@import './app/styles/checkbox.scss';
@import './app/styles/dialog.scss';
@import './app/styles/expansion-panel.scss';
@import './app/styles/snackbar.scss';
@import './app/styles/slide-toggle.scss';
@import './app/styles/table.scss';
@import './app/styles/tabs.scss';
// utils
@import './app/styles/flex.scss';
@import './app/styles/color.scss';
@import './app/styles/spacings.scss';
@import './app/styles/helper-classes.scss';

html,
body {
    height: 100%;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--color-primary);
    margin: 0;
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

h1 {
    margin-top: 0;
    margin-bottom: 24px;
}

h2 {
    font-size: 1.5rem;
}

.qpx-table-col-header {
    font-weight: 600 !important;
    color: #103441 !important;
    font-size: 1rem !important;
    padding: 0rem 0rem 0.75rem 0rem;
}

/*Start Form Group Styling */
.invalid-input {
    border: 1px solid red !important;
}

/*End Form Group Styling */
// TODO: remove this when QpxFormFieldInput and QpxInput are in use
input::placeholder {
    color: var(--color-primary) !important;
}

input:disabled::placeholder {
    color: var(--color-grey1) !important;
}

.apexcharts-canvas {
    margin: 0 auto;
}

.qpx-thin-scrollbar::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    background: var(--color-grey-4);
}

.qpx-thin-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: var(--color-grey-1);
}

apx-chart {
    .apexcharts-text.apexcharts-datalabel-value {
        font-size: 14px;
        font-weight: 600;
    }
}

ngx-extended-pdf-viewer .textLayer .highlight.selected {
    background-color: var(--highlight-selected-bg-color) !important;
}
